/* Temporary embedded in public index.html */

.quill  {
  flex-grow: 1;
}

.quill--consultation-note .ql-container.ql-snow  {
  background-color: #ffdedb;
}

.quill--consultation-note .ql-editor {
  padding-left: 75px;
}

.quill--consultation-note .ql-editor.ql-blank::before {
  left: 75px;
}

.quill--consultation-note.nl .ql-editor {
  padding-left: 87px;
}

.quill--consultation-note.nl .ql-editor.ql-blank::before {
  left: 87px;
}

.ql-editor{
  max-height: 114px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

.quill-full .ql-editor {
  max-height: calc(100vh - 20vh - 214px);
}

.ql-container.ql-snow {
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #ccc;
  border-top: 0;
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.ql-toolbar.ql-snow {
  background-color: #f9fafb;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
  border-top-right-radius: .375rem;
  border-top-left-radius: .375rem;
}

.ql-editor .personalized-tag {
  position: relative;
  display: inline-block;
  padding: .125rem 1.25rem .125rem .425rem;
  border-radius: 4px;
  background: rgba(241, 246, 246);
  font-size: .875rem;
  color: rgba(83, 127, 127);
  cursor: pointer;
}

.ql-editor .personalized-tag:hover::before,
.ql-editor .personalized-tag:hover::after {
  opacity: 1;
}

.ql-editor .personalized-tag::before,
.ql-editor .personalized-tag::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 1px;
  top: 50%;
  right: .425rem;
  border-radius: 2px;
  background-color: currentColor;
  transform-origin: center;
  transition: opacity .2s;
  opacity: .5;
}

.ql-editor .personalized-tag::before {
  transform: rotate(45deg);
}

.ql-editor .personalized-tag::after {
  transform: rotate(-45deg);
}

.ql-editor u .personalized-tag {
  text-decoration: underline;
}

.ql-toolbar .ql-variables {
  width: 200px;
}

.ql-toolbar .ql-variables .ql-picker-label::before {
  content: attr(data-label);
}

.ql-toolbar .ql-variables .ql-picker-options .ql-picker-item:first-child {
  display: none;
}

.ql-toolbar .ql-variables .ql-picker-item::before {
  content: attr(data-label);
}

.ql-formats button:disabled {
  cursor: default;
}

.ql-formats button:disabled .ql-fill,
.ql-formats button:disabled:hover .ql-fill{
  fill: #d2d6dc;
}

.ql-formats .ql-skincarePlan:disabled,
.ql-formats .ql-skincarePlan:disabled:hover {
  color: #d2d6dc;
}

.ql-formats button:disabled .ql-stroke,
.ql-formats button:disabled:hover .ql-stroke,
.ql-formats.disabled .ql-picker-label .ql-stroke {
  stroke: #d2d6dc;
}

.ql-formats.disabled .ql-picker-label {
  color: #d2d6dc;
}
